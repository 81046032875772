import "@fontsource/jura";
import "@fontsource/play";

import "scss/_index.scss";

import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import "dayjs/locale/es";

dayjs.locale("es");
dayjs.extend(relativeTime);

fetch("https://cluster.qb.fcen.uba.ar/status.json").then((data) => {
  return data.json().then((status) => {
    const nodos = document.getElementById("nodos");

    Object.keys(status).forEach(function (k) {
      const online = status[k].online ? "online" : "offline";
      const time = status[k].online
        ? dayjs(status[k].boot).add(3, "hour").toNow(true)
        : "";
      const url =
        k != "nas" && k != "dualipa" && k != "cranex" && k != "nodo0"
          ? `href="http://mon.cluster.qb.fcen.uba.ar/` + k + `/"` + k + `/`
          : "";
      nodos.innerHTML +=
        `<div class="nodo">
      <div class="titulo">
        <div class="status-dot ` +
        online +
        `"></div>
        <div class="nombre">
          <a ` +
        url +
        `>` +
        k +
        `
          <div class="uptime">` +
        time +
        `</div>
        </div>
      </div>
    </div>`;
    });
  });
});
